<template>
  <el-container style="height: 100%">
    <el-container style="overflow:hidden;">
      <el-aside class="left gz-border-right gz-border-bottom" style="width: 200px">
        <el-menu :default-active="currentItemName" :collapse="isCollapse" :unique-opened="true" class="el-menu-vertical-demo gz-border-right-0">
          <el-header class="gz-backcolor gz-border-bottom gz-p-0" style="line-height: 60px; overflow: hidden">
            <div style="text-align: center; font-weight: bold">
              <div style="font-size: 20px" v-if="isCollapse"><img class="align-middle" src="/images/tms.png"></div>
              <div v-else><img class="align-middle" src="/images/tms.png"><span class="gz-ml-1 align-middle gz-text-default">峰越达TMS</span></div>
            </div>
          </el-header>
          <gz-menu v-for="(item, index) in menuData" :key="index" :data="item"></gz-menu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header class="gz-backcolor gz-layout gz-layout-row gz-border-bottom gz-px-2 gz-pr-3" style="line-height: 60px">
          <div style="font-size: 1.5rem; cursor: pointer" class="gz-mr-2 gz-text-default">
            <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="isCollapse = !isCollapse"></i>
          </div>
          <div class="gz-layout-item"></div>
          <!-- <div class="userinfo">主应用的state：{{ JSON.stringify(user) }}</div> -->
          <div class="gz-text-default">{{ user.homeTitle }}</div>
          <div class="gz-ml-3">
            <!-- <span class="gz-text-secondary">当前用户：</span> -->
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="userinfo" style="cursor: pointer">
                <img :src="
                  serverHost +
                  '/fileServer' +
                  (user.img || '/images/headerimage/default.png')
                " style="
                  width: 32px;
                  height: 32px;
                  vertical-align: middle;                  
                " />
                <span class="gz-ml-1">{{ user.userName }}</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" style="margin-top: -15px">
                <el-dropdown-item command="userCenter" icon="el-icon-user">个人中心</el-dropdown-item>
                <el-dropdown-item command="editPwd" icon="el-icon-unlock">修改密码</el-dropdown-item>
                <el-dropdown-item command="out" divided icon="el-icon-switch-button">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main class="gz-p-0 gz-layout">
          <div class="gz-pr-2 gz-pb-1 gz-pr-3 gz-tab-nav gz-border-bottom gz-bg-light gz-layout gz-layout-row">
            <div class="gz-layout-item">
              <el-tag size="-" v-for="(item, index) in openPages" :class="
                currentItemName == item.id
                  ? 'gz-mt-1 gz-text-primary gz-tag-active'
                  : 'gz-mt-1 gz-text-body'
              " :key="index" :closable="item.url !== '/common/home'" class="gz-bg-white gz-border gz-tag gz-ml-2" @close="handleCloseTag(item)" @click="handleClickTag(item)">{{ item.label }}</el-tag>
            </div>
            <el-dropdown class="gz-ml-2 gz-mt-1" @command="handleCloseCommand">
              <span class="el-dropdown-link" style="cursor: pointer">
                快捷操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="closeCurrent">关闭当前
                </el-dropdown-item>
                <el-dropdown-item command="closeOther">关闭其他</el-dropdown-item>
                <el-dropdown-item command="closeAll" divided>关闭全部</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="gz-layout-item">
            <div v-for="(app, index) in microApps" :key="index" :id="app.name" v-show="app.name == currentAppName" class="gz-subcontent"></div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <el-footer>
      <p>
        Copyrights@2022
      </p>
    </el-footer>
  </el-container>
</template>

<script>
/* eslint-disable */
import NProgress from "nprogress";
import loadMicroApp from "./common";

import GzMenu from "../gz-menu";
import validate from "../mixins/validatelogin";
import CommonURLs from "../data/commonURL";

import { mapState } from "vuex";
import { utils } from "yesweb-libs/utils";

export default {
  name: "readyuser",
  components: {
    GzMenu,
  },
  mixins: [validate],
  data() {
    return {
      isCollapse: false,
      currentItemName: "",
      currentAppName: "",
      microApps: [],
      appCache: [],
      commonMenu: [],
    };
  },
  created() {
    if (this.mixins_istiaozhuan) return;

    NProgress.start();

    this.$eventHub.$on("onHistoryChanged", this.bindCurrent);

    this.microApps = this.$store.getters["global/microApps"].map((item) => {
      return {
        ...item,
        container: "#" + item.name, // 子应用挂载的div
      };
    });

    NProgress.done();
    // 添加首页
    var obj = this.arrayFind(
      this.allURLs,
      (item) => item.type == "item" && item.url == "/common/home",
      (obj) => obj.items
    );
    
    if (obj) {
      var index = this.openPages.findIndex((a) => a.id == obj.id);
      if (index == -1) {
        this.$store.dispatch("global/addOpenPage", obj);
      }
      // console.log('=============readyuser.create:'+window.location.pathname)
      this.bindCurrent();
    }
  },
  computed: {
    // 通过global获取user的信息
    ...mapState("global", {
      menuData: (state) => state.menu,
      openPages: (state) => state.openPages,
      serverHost: (state) => state.serverHost,
    }),
    allURLs() {
      return this.menuData.concat(CommonURLs);
    },
  },
  watch: {},
  beforeDestroy() {
    // console.log('reeadyuser beforeDestroy bindCurrent')
    this.$eventHub.$off("onHistoryChanged", this.bindCurrent);
  },
  methods: {
    handleMenu(obj, apps) {
      if (obj.type == "group") {
        obj.items.forEach((item) => {
          this.handleMenu(item, apps);
        });
      } else {
        var currentApp = apps.find((a) => a.name == obj.appName);
        if (currentApp == undefined || currentApp == null) {
          return;
        }

        obj.url = currentApp.activeRule + (obj.path == "/" ? "" : obj.path);
      }
    },

    // 关闭tag
    handleCloseTag(item) {
      var index = this.openPages.indexOf(item);
      this.$store.dispatch("global/removePage", item.id);
      if (index >= 1) {
        this.gotoItem(this.openPages[index - 1])
      }
    },

    // 点击tag
    handleClickTag(item) {
      this.gotoItem(item)
    },
    gotoItem(item) {
      var url = item.url;
      if (item.localhref) url = item.localhref;
      history.pushState(null, url, url);
    },
    getCurrentAPP() {
      var url = window.location.pathname;
      var app = this.microApps.find(
        (item) => url.indexOf(item.activeRule) == 0
      );
      return app;
    },

    loadApp() {
      var app = this.getCurrentAPP();
      console.log("==============readuser");
      var index = this.appCache.findIndex((a) => a.key == app.name);
      if (index == -1) {
        this.appCache.push({
          key: app.name,
          app: loadMicroApp(app),
        });
      }

      this.currentAppName = app.name;

      // console.log('====================reeadyuser loadApp')
    },
    isMatchUrl(routeUrl, _currentUrl) {
      // 如果存在参数,就不能通过 routeUrl == _currentUrl了
      // 比如：_currentUrl = /xqtms/demosales/update/APP0007 这个url要能正确匹配路由 routeUrl=/xqtms/demosales/update/:id
      if (routeUrl.indexOf("/:") > 0) {
        var reg = new RegExp("/:[^/]+");
        var regStr2 = routeUrl.replace(reg, "/[^/]+");
        var reg2 = new RegExp(regStr2, "i");
        return reg2.test(_currentUrl);
      } else {
        return routeUrl.toLowerCase() == _currentUrl.toLowerCase();
      }
    },

    bindCurrent() {
      // console.log('====================reeadyuser bindCurrent')
      NProgress.start();
      this.loadApp();

      var pathname = window.location.pathname.toLowerCase();
      var _current = pathname;
      if (pathname[pathname.length - 1] == "/") {
        _current = pathname.substring(0, pathname.length - 1);
      }

      var obj = this.arrayFind(
        this.allURLs,
        (item) =>
          (item.type == "item" || item.type == "page") &&
          this.isMatchUrl(item.url, _current),
        (obj) => obj.items
      );

      if (obj) {
        this.currentItemName = obj.id;
        var index = this.openPages.findIndex((a) => a.id == obj.id);
        obj.localhref = window.location.href;
        if (index == -1) {  
          this.$store.dispatch("global/addOpenPage", obj);
        }
      } else {
        this.currentItemName = "";
      }
      NProgress.done();

      // console.log('====================reeadyuser bindCurrent after')
    },
    arrayFind(arr, filter, children) {
      for (var i = 0; i < arr.length; i++) {
        var obj = arr[i];
        if (filter(obj)) {
          return obj;
        } else {
          var c = children(obj);
          if (c) {
            var o = this.arrayFind(c, filter, children);
            if (!!o) return o;
          }
        }
      }
      return null;
    },
    handleCloseCommand(command) {
      switch (command) {
        case "closeCurrent":
          {
            var index = this.openPages.findIndex(
              (a) => a.id == this.currentItemName
            );
            this.$store.dispatch("global/removePage", this.currentItemName);
            if (index >= 1) {
              this.gotoItem(this.openPages[index - 1])
            }
          }
          break;
        case "closeOther":
          {
            this.$store.dispatch(
              "global/removeOtherPage",
              this.currentItemName
            );
          }
          break;
        case "closeAll":
          {
            this.$store.dispatch("global/clearPage");
            this.gotoItem(this.openPages[0])
          }
          break;
      }
    },
    // 点击右上角用户菜单
    handleCommand(command) {
      switch (command) {
        case "userCenter":
          {
            history.pushState(null, "/common/usercenter", "/common/usercenter");
          }
          break;
        case "editPwd":
          {
            history.pushState(null, "/common/modifyPwd", "/common/modifyPwd");
          }
          break;
        case "out":
          {
            this.$store.dispatch("global/clearPage");
            this.$store.dispatch("global/updateUser", { isUpdate: true });

            this.appCache.forEach((item) => {
              item.app.unmount();
            });
            var loginURL = "/common/login";
            this.mixins_istiaozhuan = true;
            history.pushState(null, loginURL, loginURL);
          }
          break;
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.gz-tab-nav {
  span {
    // margin-left: 10px;
    line-height: 28px;
  }
  // span:first-child {
  //   margin-left: 0;
  // }
}
.gz-tag {
  cursor: pointer;
  &-active {
    font-weight: bold;
  }
}
.gz-menu {
  width: 200px;
  &-collapse {
    width: 64px;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}

.left {
  width: auto !important;
}

.userinfo {
  color: #3398dc;
  font-weight: bold;
}
</style>

<style lang="scss">
// .github-corner:hover .octo-arm {
//   animation: octocat-wave 560ms ease-in-out;
// }
// @keyframes octocat-wave {
//   0%,
//   100% {
//     transform: rotate(0);
//   }
//   20%,
//   60% {
//     transform: rotate(-25deg);
//   }
//   40%,
//   80% {
//     transform: rotate(10deg);
//   }
// }
// @media (max-width: 500px) {
//   .github-corner:hover .octo-arm {
//     animation: none;
//   }
//   .github-corner .octo-arm {
//     animation: octocat-wave 560ms ease-in-out;
//   }
// }
// .layout-wrapper {
//   .layout-header {
//     height: 50px;
//     width: 100%;
//     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
//     line-height: 50px;
//     position: relative;
//     .logo {
//       float: left;
//       margin: 0 50px;
//     }
//     .sub-apps {
//       list-style: none;
//       margin: 0;
//       li {
//         list-style: none;
//         display: inline-block;
//         padding: 0 20px;
//         cursor: pointer;
//         &.active {
//           color: #42b983;
//           text-decoration: underline;
//         }
//       }
//     }
//   }
// }
</style>
