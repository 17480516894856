<template>
  <component ref="syncCom" :is="CurrentCommponent"></component>
</template>

<script>
/* eslint-disable */
import Empty from './index/empty'
import ReadyUser from './index/readyuser'

export default {
  components: {
    ReadyUser,
    Empty
  },
  data () {
    return {
      CurrentCommponent: ''
    }
  },
  created () {
    this.listenRouterChange()
    this.loadURL()
  },
  mounted () {

  },
  methods: {
    listenRouterChange () {
      const _wr = function (type) {
        const orig = history[type]
        return function () {
          const rv = orig.apply(this, arguments)
          const e = new Event(type)
          e.arguments = arguments
          window.dispatchEvent(e)
          return rv
        }
      }
      history.pushState = _wr('pushState')

      window.addEventListener('pushState', this.rootbindCurrent)
      window.addEventListener('popstate', this.rootbindCurrent)

      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('pushState', this.rootbindCurrent)
        window.removeEventListener('popstate', this.rootbindCurrent)
      })
    },
    rootbindCurrent (e) {
      if (e.type === 'popstate' && e.singleSpa === true) {
        // history.pushState的时候也会触发popstate事件，这里判断popstate事件的singleSpa属性，如果是true，则直接返回
        return
      }
      this.loadURL()
    },
    loadURL () {
      var url = window.location.pathname


      if (window.location.pathname === "/" || window.location.pathname === '') {
        history.pushState(null, '/common/home', '/common/home')
        return
      }
      if (url === '/common/login') {
        this.CurrentCommponent = "Empty"
      } else {
        this.CurrentCommponent = "ReadyUser"
      }

      this.$nextTick(() => {
        this.$eventHub.$emit('onHistoryChanged')
      })
    }
  }
}
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
.gz-subcontent {
  height: 100%;
  & > div {
    height: 100%;
  }
}
</style>

