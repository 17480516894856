const urls = [
  {
    appName: 'sub-common',
    id: '_C001',
    label: '首页',
    type: 'item',
    url: '/common/home',
  },
  {
    appName: 'sub-common',
    id: '_C002',
    label: '登录',
    type: 'item',
    url: '/common/login',
  },
  {
    appName: 'sub-common',
    id: '_C003',
    label: '个人中心',
    type: 'item',
    url: '/common/UserCenter',
  },
  {
    appName: 'sub-common',
    id: '_C004',
    label: '修改密码',
    type: 'item',
    url: '/common/modifyPwd',
  },
  {
    appName: 'sub-common',
    id: '_C005',
    label: '没有权限',
    type: 'item',
    url: '/common/noAuthorize',
  },
]

export default urls
