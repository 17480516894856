import { mapState } from 'vuex'
import { ajaxSync } from 'yesweb-libs'
import Promise from 'Promise'
const request = {
  getCurrentUser(context) {
    return ajaxSync.get(context, '/PCUser/getCurrentUser')
  },
  getMenus(context) {
    return ajaxSync.get(context, '/microapps/SysMenu/GetPCMenu')
  },
  userAuthorize(context) {
    return ajaxSync.get(context, '/microapps/SysMenu/UserAuthorize')
  },
}

export default {
  data() {
    return {
      mixins_istiaozhuan: false,
    }
  },
  computed: {
    // 通过global获取user的信息
    ...mapState('global', {
      user: (state) => state.user,
    }),
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (this.user.isLoad === true) {
        return
      }
      var me = this
      var login = () => {
        me.$store.dispatch('global/updateUser', { isUpdate: true })
        var loginURL = '/common/login'
        me.mixins_istiaozhuan = true
        history.pushState(null, loginURL, loginURL)
      }

      let res_user = request.getCurrentUser(this)
      let currentUser = JSON.parse(res_user)
      if (currentUser.error !== 0) {
        login()
        return
      }
      this.$store.dispatch('global/updateUser', {
        isLogin: true,
        isUpdate: true,
        isLoad: true,
        token: this.user.token,
        secretID: this.user.secretID,
        secretKey: this.user.secretKey,
        remember: this.user.remember,
        account: currentUser.data.account,
        userName: currentUser.data.userName,
        img: currentUser.data.img,
        homeTitle: currentUser.data.homeTitle,
      })

      let res_menus = request.getMenus(this)
      let currentMenus = JSON.parse(res_menus)
      this.$store.dispatch('global/updateUserMicroApps', currentMenus.data.apps)
      this.$store.dispatch('global/updateMenu', currentMenus.data.menus)

      var res_userAuthorize = request.userAuthorize(this)
      let userAuthorize = JSON.parse(res_userAuthorize)

      this.$store.dispatch('global/updateUserAuthorize', userAuthorize.data)
    },
  },
}
