<template>
  <el-popconfirm class="gz-button-poptip" v-if="showWarningEx" :title="args.title" :confirmButtonText="args.confirmButtonText" :cancelButtonText="args.cancelButtonText" :disabled="!args.enable" @cancel="cancleClick" @confirm="handleClick">
    <el-button slot="reference" :size="size" :type="type" :plain="plain" :round="round" :circle="circle" :loading="loading" :disabled="disabled" :icon="loading==true?'el-icon-loading':icon" :autofocus="autofocus" :native-type="nativeType" @click="showPoptip">
      <slot></slot>
    </el-button>
  </el-popconfirm>
  <el-button v-else :size="size" :type="type" :plain="plain" :round="round" :circle="circle" :loading="loading" :disabled="disabled" :icon="loading==true?'el-icon-loading':icon" :autofocus="autofocus" :native-type="nativeType" @click="handleClick">
    <slot></slot>
  </el-button>

</template>
<style lang="css" scoped>
.gz-button-poptip {
  margin-left: 10px;
}
.el-button-group>.gz-button-poptip:not(:first-child){
  margin-left: -1px;
}
</style>
<script>
export default {
  name: "GzButton",
  mixins: [],
  components: {},
  props: {
    permission: {
      type: Number,
      default: 0,
    },
    usePopConfirm: {
      type: Boolean,
      default: false,
    },
    sync: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "default",
    },
    size: String,
    icon: {
      type: String,
      default: "",
    },
    nativeType: {
      type: String,
      default: "button",
    },
    disabled: Boolean,
    plain: Boolean,
    autofocus: Boolean,
    round: Boolean,
    circle: Boolean,
  },
  data() {
    return {
      loading: false,
      visible: false,
      args: {
        enable: false,
        title: "",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        data: null,
      },
    };
  },

  computed: {
    showWarningEx() {
      return this.usePopConfirm;
    },
  },
  methods: {
    cancleClick() {
      this.args.enable = false;
    },
    handleClick() {
      if (this.sync === false) this.loading = true;
      this.$emit("click", this, this.args);
    },
    showPoptip() {
      this.args.enable = false;
      this.$emit("data-handle", this.args, () => {
        this.args.enable = true;
      });
    },
  },
  mounted() {},
};
</script>
